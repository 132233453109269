<template>
  <div class="menu-overlay">
    <div class="flex justify-content-between align-items-end p-0 flex-wrap">
      <div class="flex justify-content-start align-items-end p-0 ml-2">
        <i class="fi-rr-menu-burger p-link mr-2 fg-lightAmber button" title="Menü" @click="this.toggle"></i>
        <i v-if="editMode" class="fi-rr-arrows p-link mr-2 fg-lightAmber handle button" title="Bewegen"></i>
        <Menu :id="'boxMenu_' + 1" ref="menu" :model="menuItems" :popup="true" :baseZIndex="999" />
        <!-- <span :class="fgColor1" class="hidden md:block sensorFont mt-1">{{ department }} {{ departmentLabel }}/</span> -->
        <!-- <div v-if="alarmStatus > 0" class="alarm-overlay">
          <div class="alarmticker">
            <span>{{ alarmTicker }}</span>
          </div>
        </div>  -->
      </div>
      <div class="flex justify-content-end align-items-end p-0">
        <div v-if="editMode">
          <p class="text-small fg-white bg-black">{{ widgetName }} / {{ widgetLabel }} <span v-if="isMaster" class="text-xs">{{ widgetId }}</span></p>
        </div>

      </div>
    </div> 
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from 'vuex';
import store from '@/store';
// import dialogChartSingle from '@/components/dialog/chartSingle.vue';
// import widgetGuiValue from '@/components/fragments/widgetGuiValue.vue';

export default defineComponent({
  name: "widgetTitleBar",
  emits: ['menuClick'],
  setup() {},
  components: {
    // widgetGuiValue,
    // dialogChartSingle,
  },
  props: {
    moduleData: {
      type: Object,
      required: true
    },
    widgetInfo: {
      type: Object,
      required: true
    },
    fgColor: {
      type: String,
      required: false
    },
    alarmList: {
      type: Array,
      required: false
    },
    menuItems: {
      type: Array,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters({
        editMode: 'getEditMode',
        getNodeWidget: 'getNodeWidget',
        getOneDepartment: 'types/getOneDepartment',
        getOneCategory: 'types/getOneCategory',
        getStyleColors: 'getStyleColors',
        getStylePatterns: 'getStylePatterns',
        getModules: 'opcua/getModules',
        getModule: 'opcua/getModule',
        getNode: 'opcua/getNode',
        getModuleFetched: 'opcua/getModuleFetched',
        getSynoptoCode: 'types/getSynoptoCode',
        isMaster: 'auth/isMaster',
    }),
    alarmTicker: function () {
      let alarmStr = '';
      if (this.alarmList.length > 0) {
        this.alarmList.forEach((alarm => {
          console.log('widgettitlebar', alarm);
          if (alarm.state > 0) alarmStr += `🔴 ${alarm.label} `;
        }));
      }
      return alarmStr;
    },
    alarmStatus: function () {
      let alarmCount = 0;
      if (this.alarmList.length > 0) {
        this.alarmList.forEach((alarm => {
          console.log(alarm);
          if (alarm.state > 0) alarmCount += 1;
        }));
      }
      return alarmCount;
    },
    value1: function () {
      if (typeof(this.value) === "object") return this.value.value;
      else if (typeof(this.value) === "string") return this.value;
      return '';
    },
    unit1: function () {
      if (typeof(this.value) === "object") {
        if (this.value.unit.includes("#")) return '';
        return this.value.unit;
      }
      return '';
    },
    icon1: function () {
      return "fi fi-rr-home";
    },
    fgColor1: function () {
      if (this.fgColor) {
        return `${this.fgColor}`;
      }
      return '';
    },
    department: function () {
      if (this.moduleData.metadata.department) return this.moduleData.metadata.department;
      else return "";
    },
    label: function () {
      if (this.moduleData.metadata.label) return this.moduleData.metadata.label;
      else return "";
    },
    description: function () {
      if (this.moduleData.metadata.description) return this.moduleData.metadata.description;
      else return "";
    },
    departmentKey: function () {
      return this.moduleDepartment().key;
    },
    departmentLabel: function () {
      return this.moduleDepartment().label;
    },
    isRecord() {
      if (typeof(this.value) === "object") {
        if (this.value.record === "true") return true;
      }
      return false;
    },
    showPointer() {
      if (this.isRecord) return "cursor-pointer";
      else return "";
    },
    widgetName: function () {
			if (this.widgetInfo && this.widgetInfo.component) return this.widgetInfo.component;
			return '';
		},
		widgetId: function () {
			if (this.widgetInfo && this.widgetInfo.i) return this.widgetInfo.i;
			return '';
		},
		widgetLabel: function () {
			if (this.widgetInfo && this.widgetInfo.label) return this.widgetInfo.label;
			return '';
		},
  },
  created() {
  },
  mounted() {
  },
  methods: {
    toggle(event) {
        this.$refs.menu.toggle(event);
    },
    moduleDepartment: function () {
        if (this.moduleData !== null) {
            if (this.moduleData.metadata.department !== null) {
                return this.getOneDepartment(this.moduleData.metadata.department);
            }
        }
        return { key: store.getters.getNoval, label: store.getters.getNoval };
    },
    moduleCategory: function () {
        if (this.moduleData !== null) {
            if (this.moduleData.metadata.category !== null) {
                return this.getOneDepartment(this.moduleData.metadata.category);
            }
        }
        return { key: store.getters.getNoval, label: store.getters.getNoval };
    },
    showChart(value) {
      if (this.isRecord) {
        if (value === 'value1') this.showChart1 = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.menu-overlay {
    top: 0px;
    right: 0px;
    height: 20px;
    margin-top: -4px;
    margin-bottom: -15px;
    margin-left: -10px;
    margin-right: -8px;
    z-index: 9999;
}
.menu-overlay .button {
  opacity: 0.4;
  transition: 0.2s ease;
  text-align: right;
}
.button:hover {
    opacity: 1;
}
.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}
.tltl {
  font-size: 0.8em;
}

@keyframes scroll-left {
0% {
    transform: translateX(200%);
}
100% {
    transform: translateX(-200%);
}
}
</style>